import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";
import { useState,  } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function IndModal(props) {
  const [modalData, setModalData] = useState(props);


  function handleDriveOff(plate, pump, date) {
    var month = new Date(date).getMonth() + 1;
    var day = new Date(date).getDate();
    var year = new Date(date).getFullYear();
    var hour = new Date(date).getHours();
    var mins = new Date(date).getMinutes();

    if (day < 10) {
      var newDay = "0" + day;
    } else {
      var newDay = day;
    }

    if (month < 10) {
      var newMonth = "0" + month;
    } else {
      var newMonth = month;
    }

    if (hour < 10) {
      var newHour = "0" + hour;
    } else {
      var newHour = hour;
    }
    if (mins < 10) {
      var newMins = "0" + mins;
    } else {
      var newMins = mins;
    }
    var fullDate = year + "-" + newMonth + "-" + newDay;
    var time = newHour + ":" + newMins;
    var confirm = window.confirm("Please confirm this was a drive off");
    if (confirm === true) {
      window.open(
        `https://driveoff.racetrackpitstop.co.uk?reg=${plate}&pump=${pump}&site=${props.site}&date=${fullDate}&time=${time}`,
        "_blank"
      );
    } else {
      window.location.reload();
    }
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h4" component="h2">
        Details for Reg <span className="dvlaDetails">{modalData.d.licensePlate}</span>
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <ul>
          <li>
            Pump:
            <span className="dvlaDetails">{modalData.d.line}</span>
          </li>
          <li>
            Confidence level:
            <span className="dvlaDetails">{modalData.d.allData.confidenceLevel}</span>
          </li>

          <li>
            Status:
            <span className="dvlaDetails">{modalData.d.status}</span>
          </li>
          <li>
            DVLA Make:
            <span className="dvlaDetails">{modalData.d.allData.dvlaMake}</span>
          </li>
          <li>
            DVLA Colour:
            <span className="dvlaDetails">
              {modalData.d.allData.dvlaColour}
            </span>
          </li>
          <li>
            DVLA MOT Status:
            <span className="dvlaDetails">{modalData.d.allData.dvlamot}</span>
          </li>
          <li>
            Detected Colour:
            <span className="dvlaDetails">
              {modalData.d.allData.color.toUpperCase()}
            </span>
          </li>

          <li>
            DVLA Fuel:
            <span className="dvlaDetails">{modalData.d.allData.dvlaFuel}</span>
          </li>
          <li>
            DVLA Registration Year:
            <span className="dvlaDetails">{modalData.d.allData.dvlaYear}</span>
          </li>
          <li>
            DVLA Tax Status:
            <span className="dvlaDetails">{modalData.d.allData.dvlatax}</span>
          </li>
        </ul>
        <IconButton sx={{ marginLeft: "42%" }}>
          <TaxiAlertIcon
            onClick={() =>
              handleDriveOff(
                modalData.d.licensePlate,
                modalData.d.line,
                modalData.d.date
              )
            }
            color="warning"
            sx={{ fontSize: 50 }}
          />
        </IconButton>
      </Typography>
    </Box>
  );
}

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import "./styles/pumps.css";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AlertTitle from "@mui/material/AlertTitle";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import useSound from "use-sound";
import soundAlert from "../images/Sound.mp3";
import noEntry from "../images/noEntry.png";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { io } from "socket.io-client";
import PumpModal from "./PumpModal";
import Police from "../images/alert.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Pump(props) {
  const [license, setLicense] = useState("");
  const [color, setColor] = useState("");
  const [status, setStatus] = useState("");
  const [alert, setAlert] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(0.75);
  const [carDetails, setCarDetails] = useState([]);
  const [dangerPlate, setDangerPlate] = useState("");
  const [time, setTime] = useState("");
  const [minutes, setMinutes] = useState("");
  const [open, setOpen] = useState(false);
  const [showNoEntry, setShowNoEntry] = useState(false);
  const [showPolice, setShowPolice] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  var interval = null;
  const [play, { stop }] = useSound(soundAlert, {
    playbackRate,
    volume: 0.8,
  });

  useEffect(() => {
    // setInterval(() => {
    const socket = io("https://nitrocam-back.nitrosolutions.co.uk");
    // const socket = io("http://10.0.0.174:4024");
    socket.connect();
    console.log("connected");
    axios
      .get(
        `https://nitrocam-back.nitrosolutions.co.uk/car/site?site=${props.site}&pump=${props.pump}`
      )
      .then((res) => {
        setLicense(res.data.licensePlate);
        setColor(res.data.color);
        setStatus(res.data.status);
        setCarDetails(res.data);
        if (res.data.previousDriveoff) {
          setShowNoEntry(true);
        }
        else if (res.data.policeCheck) {
          setShowPolice(true);
        }
        else {
          setShowNoEntry(false);
          setShowPolice(false);
        }
        var dateArrive = new Date(res.data.dateTime).toLocaleTimeString();
        setTime(dateArrive);
        //var now = moment();
        var now = moment(res.data.dateTime);
        //var duration = moment
          //.duration(now.diff(new Date(res.data.dateTime)))
          //.asMinutes();
        //setMinutes(duration.toFixed(0));
        var duration = moment.duration(moment().diff(now)).asMinutes();
        setMinutes(duration.toFixed(0));
        interval = setInterval(() => {
          //now = moment();
          //duration = moment
            //.duration(now.diff(new Date(res.data.dateTime)))
            //.asMinutes();
          //setMinutes(duration.toFixed(0));
          duration = moment.duration(moment().diff(now)).asMinutes();
          setMinutes(duration.toFixed(0));
        }, 10000);
        // setTimeout(() => {
        if (res.data.status === "red") {
          setDangerPlate(res.data.licensePlate);
          setAlert(true);
          handleClick();
        }
        // }, 5000);
      })
      .catch((err) => {
        console.log(err);
      });

    socket.on(`${props.site}_${props.pump}`, (d) => {
      console.log("New car on pump");
      if (d) {
        setLicense(d.licensePlate);
        setColor(d.color);
        setStatus(d.status);
        setCarDetails(d);
        if (d.previousDriveoff) {
          setShowNoEntry(true);
        }
        else if (d.policeCheck) {
          setShowPolice(true);
        }
        else {
          setShowNoEntry(false);
          setShowPolice(false);
        }
        clearInterval(interval);
        var dateArrive = new Date(d.dateTime).toLocaleTimeString();
        setTime(dateArrive);
        //interval = setInterval(() => {
          //var now = moment();
          //var duration = moment
            //.duration(now.diff(new Date(d.dateTime)))
            //.asMinutes();
          //setMinutes(duration.toFixed(0));
          //var now = moment();
          //var duration = moment.duration(moment().diff(now)).asMinutes();
          //setMinutes(duration.toFixed(0));
        //}, 10000);
        if (d.status === "red") {
          setDangerPlate(d.licensePlate);
          setAlert(true);
          handleClick();
        }
      }else{
        setLicense("");
        setColor("");
        setStatus("");
        setAlert(false);
        setCarDetails([]);
        setDangerPlate("");
        setTime("");
        setMinutes("");
        setShowNoEntry(false);
        setShowPolice(false);
      }

    });
    socket.on(`Live_Refresh`, (d) => {
      window.location.reload();
    });
    return () => {
      socket.disconnect();
    };
    //   }, 5000);
  }, []);

  function Close() {
    setAlert(false);
  }

  function handleClick() {
    document.getElementById("btn").click();
  }

  function playSound() {
    setPlaybackRate(playbackRate + 0.1);
    play();
  }

  function handleDriveOff(plate, pump) {
    var month = new Date().getMonth() + 1;
    var day = new Date().getDate();
    var year = new Date().getFullYear();
    var hour = new Date().getHours();
    var mins = new Date().getMinutes();

    if (day < 10) {
      var newDay = "0" + day;
    } else {
      var newDay = day;
    }

    if (month < 10) {
      var newMonth = "0" + month;
    } else {
      var newMonth = month;
    }

    if (hour < 10) {
      var newHour = "0" + hour;
    } else {
      var newHour = hour;
    }
    if (mins < 10) {
      var newMins = "0" + mins;
    } else {
      var newMins = mins;
    }
    var fullDate = year + "-" + newMonth + "-" + newDay;
    var time = newHour + ":" + newMins;
    var confirm = window.confirm("Please confirm this was a drive off");
    if (confirm === true) {
      window.open(
        `https://driveoff.racetrackpitstop.co.uk/driveoff?reg=${plate}&pump=${pump}&site=${props.site}&date=${fullDate}&time=${time}`,
        "_blank"
      );
    } else {
      window.location.reload();
    }
  }

  return (
    <>
      {alert === true ? (
        <div className="alert">
          <Stack sx={{ width: "100%" }} spacing={5}>
            <Alert variant="filled" severity="error">
              <AlertTitle>Warning</AlertTitle>
              Do not authorise pump:{props.pump}
              <br /> for a car colour:{color} with reg number:{dangerPlate}
              <IconButton>
                <CloseIcon onClick={() => Close()} sx={{ color: "#fff" }} />
              </IconButton>
            </Alert>
          </Stack>
        </div>
      ) : (
        <></>
      )}
      {license !== '' ?( 
        <>
          {carDetails.carDoor && license !== '' ?(
            <div style={{display: "inline",float: "none",textAlign:"center", width:"100px"}}>
            <MeetingRoomIcon sx={{ color: "red", fontSize: 40,}} />
            <br/>
            <p style={{color:"red"}}>Door open</p>
            </div>
          ) : (
            <div style={{display: "inline",float: "none",textAlign:"center", width:"100px"}}>
            <DoorFrontIcon sx={{ color: "green", fontSize: 40,}} />
            <br/>
            <p style={{color:"green"}}>Door closed</p>
            </div>
          )}
        </>  
      ) : (
        <>
        <div style={{width:"100px"}}></div>  
        </>
      )}

      <div className="car">
        {showNoEntry ? (
          <div className="noEntry">
            <p>KNOWN DRIVEOFF</p>
            <img src={noEntry} width="250px" />
          </div>
        ) : showPolice ? (
          <div className="noEntry">
            <p>POLICE ALERT</p>
            <img src={Police} width="250px" />
          </div>
        ) : null}
        {/* <span>{license}</span>
        <p>Car Colour:{color}</p>
        <div
          className="statusBox"
          style={{
            border: `2px solid ${status}`,
            borderRadius: "10px",
            padding: "1%",
          }}
        >*/}
        {license !== "" ? (
          <>
          <span>{license}</span>
          <p>Car Colour:{color}</p>
          <div
            className="statusBox"
            style={{
              width: "135px",
              height: "145px",
              border: `2px solid ${status}`,
              borderRadius: "10px",
              padding: "2%",
              display: "inline",
              float: "none",
              textAlign: "center",
            }}
          >
            <DirectionsCarIcon
              sx={{ color: `${status}`, fontSize: 100, marginLeft: "6%" }}
              onClick={handleOpen}
            />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <PumpModal d={carDetails} site={props.site} />
            </Modal>
            <p style={{display: "inline",float: "none",}}>Status: {status}</p>
          </div></>
        ) : (
          <>
          <div
            className="statusBox"
            style={{
              width: "135px",
              height: "145px",
              border: `2px solid black`,
              borderRadius: "10px",
              padding: "2%",
              display: "inline",
              float: "none",
              textAlign: "center",
            }}
          >
          <DirectionsCarIcon
            sx={{ color: "black", fontSize: 100, marginLeft: "6%" }}
          />
          <br/>
          <p style={{display: "inline",float: "none", }}>No Car</p>
          </div>
          </>
        )}
      {/*</div>
          <DirectionsCarIcon
            sx={{ color: `${status}`, fontSize: 100, marginLeft: "6%" }}
            onClick={handleOpen}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <PumpModal d={carDetails} site={props.site} />
          </Modal>
          <p>Status: {status}</p>
        </div> */}
        {time !== "" ? (
          <>
            <p style={{ textAlign: "center" }}>
              Arrived @ {time}
              <br />
              {minutes} mins ago
            </p>
          </>
        ) : (
          <></>
        )}
        <button id="btn" onClick={() => playSound()}>
          play
        </button>
      </div>
    </>
  );
}

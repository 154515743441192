import { useState, useEffect } from "react";
import moment from "moment";
import Logo from '../images/Logo.png'
import "./styles/header.css"

export default function Header(props) {
    const [time, setTime] = useState("");

    useEffect(() => {
      dateTime();
      setInterval(() => {
        dateTime();
      }, 1000);
    },[])

    const dateTime = () => {
        setTime(moment().format("HH:mm:ss | DD-MMM-YY"));
      };

    return (
        <div className="siteHeader" >
            <img src={Logo} className="headerLogo" />
            <h1>{props.site}</h1>
            <div className="headerCol">
            <h1>{time}</h1>
            <div className="headerGroup">
              <div className="circle" style={{backgroundColor: "red"}}></div>
              <p >Action Required</p>
              <p>|</p>
              <div className="circle" style={{backgroundColor: "orange"}}></div>
              <p >Warning: manual check</p>
              <p>|</p>
              <div className="circle" style={{backgroundColor: "green"}}></div>
              <p>OK</p>
              <p>|</p>
              <div className="circle" style={{backgroundColor: "blue"}}></div>
              <p>Electric Car</p>
            </div>
            </div>
        </div>
    )

}
import CopyrightIcon from '@mui/icons-material/Copyright';
import "./styles/home.css";


export default function Footer() {
    return(
        <footer>
            <CopyrightIcon sx={{color: "#2c53a0"}}/>Developed by<a href="https://nitrosolutions.co.uk/" target="_blank"></a> Nitro Solutions
        </footer>
    )
}
import "../styles/pumps.css";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Pump from "../Pump";
import Footer from "../Footer";
import Header from "../Header";
import IndividualHistory from "./IndividualHistory";
import html2canvas from "html2canvas";
import HistoryIcon from "@mui/icons-material/History";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { io } from "socket.io-client";
import useSound from "use-sound";
import facereq from "../../images/facereq.mp3";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { AlertTitle, Stack } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: "50vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
};

export default function Bearsden() {
    const [data, setData] = useState();
    const [lastCars, setLastCars] = useState([]);
    const [showProblemBox, setShowProblemBox] = useState(false);
    const [problemImg, setProblemImg] = useState("");
    const [problem, setProblem] = useState("");
    const [devCount, setDevCount] = useState(0);
    const [showDevBtns, setShowDevBtns] = useState(false);
    const [showColours, setShowColours] = useState(true);
    const [history, setHistory] = useState(false);
    const [offline, setOffline] = useState(false)
    const exportRef = useRef();
    var site = "Bearsden";
    const [faceReqData, setFaceReqData] = useState();
    const [playbackRate, setPlaybackRate] = useState(0.75);
    const [play, { stop }] = useSound(facereq, {
        playbackRate,
        volume: 0.8,
    });
    const [actionTaken, setActionTaken] = useState("");
    const [actionList, setActionList] = useState([]);
    const [openHistory, setOpenHistory] = useState(false);
    const [openProblemReport, setOpenProblemReport] = useState(false);

    useEffect(async () => {
        if (devCount === 5) {
            setShowDevBtns(true);
        } else if (devCount > 5) {
            setDevCount(0);
            setShowDevBtns(false);
        }
        setInterval(() => {
            axios
                .get(
                    `https://nitrocam-back.nitrosolutions.co.uk/car/history?site=${site}`
                )
                .then((res) => {
                    var z = res.data.filter((d) => d.site === site);
                    // console.log(res.data);
                    setLastCars(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }, 5000);

        await axios
            .get(
                "https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/actions"
            )
            .then((resp) => {
                setActionList(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });

        //face recognition
        const socket = io("https://nitrocam-back.nitrosolutions.co.uk", { query: `site=${site}` } );
        socket.connect();

        socket.on("connect", async () => {
            setOffline(false);
        })

        socket.on(`${site}_face_data`, async (d) => {
            if (!offline) {
                await axios
                    .post(
                        "https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/received",
                        { name: d.name }
                    )
                    .then((resp) => {
                        console.log("response sent");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            console.log(d);
            setFaceReqData(d);
            setData(d);
            document.getElementById("faceReq_modal").style.display =
                "inline-block";
            document.getElementById("facereq").click();
        });

        socket.on("disconnect", (d) => {
            console.log("DISCONNECTED :", d);
            setOffline(true);
        });

        setInterval(async () => {
            if (!offline) {
                await axios.post("https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/heartbeat", { site: site }).then((resp) => {
                    console.log(`Heartbeat Submitted - ${resp.data}`)
                }).catch((err) => {
                    console.log(err)
                })
            }
        }, 150000)

        return () => {
            socket.disconnect();
        };
        //end of face recognition
    }, [devCount, faceReqData]);

    const exportAsImage = async (el) => {
        setOpenProblemReport(true);
        const canvas = await html2canvas(el);
        const image = canvas.toDataURL("image/png", 1.0);
        setProblemImg(image);
        setShowProblemBox(true);
    };

    const submitProblem = async () => {
        await axios
            .post("https://nitrocam-back.nitrosolutions.co.uk/car/help", {
                source: problemImg,
                problem: problem,
            })
            .then((res) => {
                alert("Problem Has Been Submitted!");
                setShowProblemBox(false);
                setOpenProblemReport(false);
            })
            .catch((err) => {
                alert("An error has ocurred. Please tray again later.");
            });
    };

    const manualRefresh = () => {
        axios
            .get("https://nitrocam-back.nitrosolutions.co.uk/refresh")
            .then((res) => {
                console.log(res.data);
            });
    };

    const dev = () => {
        var count = devCount;
        count++;
        setDevCount(count);
    };

    const devUpdate = (status) => {
        axios.get(`https://nitrocam-back.nitrosolutions.co.uk/admin/${status}`);
        if (status === "reset") {
            setShowColours(true);
        } else {
            setShowColours(false);
        }
    };

    // function handleHistory() {
    //     if (!history) {
    //         setHistory(true);
    //         setTimeout(() => {
    //             setHistory(false);
    //         }, 30000);
    //     } else {
    //         setHistory(false);
    //     }
    // }

    function closeFaceReq() {
        document.getElementById("faceReq_modal").style.display = "none";
    }

    const updateAction = async () => {
        if (actionTaken == "" || actionTaken == "---- ACTION TAKEN ----") {
            alert("ACTION MUST BE SELECTED");
        } else {
            await axios
                .post(
                    "https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/update",
                    { name: data.name, note: actionTaken }
                )
                .then((resp) => {
                    document.getElementById("faceReq_modal").style.display =
                        "none";
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    function playSound() {
        setPlaybackRate(playbackRate + 0.1);
        play();
    }

    return (
        <section className="home" ref={exportRef}>
            <Header site="Bearsden" />
            <button
                style={{
                    position: "fixed",
                    bottom: "10px",
                    right: "5px",
                    borderRadius: "50%",
                    backgroundColor: "lightblue",
                    border: "2px solid navy",
                    width: "50px",
                    height: "50px",
                }}
                onClick={() => exportAsImage(exportRef.current, "test")}
            >
                ?
            </button>
            <button
                onClick={() => setOpenHistory(true)}
                style={{
                    position: "fixed",
                    bottom: "10px",
                    left: "5px",
                    borderRadius: "50%",
                    backgroundColor: "lightblue",
                    border: "2px solid navy",
                    width: "50px",
                    height: "50px",
                }}
            >
                <HistoryIcon />
            </button>
            {offline ?
                <Stack sx={{ width: "100%" }} spacing={5}>
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Warning</AlertTitle>
                        Internet Connectivity Has Been Lost
                    </Alert>
                </Stack>
                : null
            }
            {/* <div className="testing" onClick={() => { dev() }}>🚧<br />WORK IN PROGRESS</div> */}
            <div className="pumps">
                <div className="pumpRow">
                    <Pump pump="1" site="Bearsden" />
                    <div className="pump">
                        <div className="pumpNumber">1</div>
                        <Divider
                            sx={{ color: "#000" }}
                            orientation="vertical"
                            flexItem
                        />
                        <div className="pumpNumber">2</div>
                    </div>
                    <Pump pump="2" site="Bearsden" />

                    <Pump pump="3" site="Bearsden" />
                    <div className="pump">
                        <div className="pumpNumber">3</div>
                        <Divider
                            sx={{ color: "#000" }}
                            orientation="vertical"
                            flexItem
                        />
                        <div className="pumpNumber">4</div>
                    </div>
                    <Pump pump="4" site="Bearsden" />
                </div>
            </div>
            {showDevBtns ? (
                <div
                    style={{
                        margin: "0 auto",
                        textAlign: "center",
                        marginBottom: "-40px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            margin: "0 auto",
                            textAlign: "center",
                            paddingTop: "10px",
                            paddingBottom: "5px",
                        }}
                    >
                        {showColours ? (
                            <>
                                <button
                                    style={{
                                        border: "1px solid black",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "2rem",
                                        backgroundColor: "red",
                                        borderRadius: "10px",
                                        padding: "25px",
                                        padding: "25px",
                                        marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                        devUpdate("danger");
                                    }}
                                >
                                    Danger
                                </button>
                                <button
                                    style={{
                                        border: "1px solid black",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "2rem",
                                        backgroundColor: "orange",
                                        borderRadius: "10px",
                                        padding: "25px",
                                        padding: "25px",
                                        marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                        devUpdate("warning");
                                    }}
                                >
                                    Warning
                                </button>
                                <button
                                    style={{
                                        border: "1px solid black",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "2rem",
                                        backgroundColor: "dodgerblue",
                                        borderRadius: "10px",
                                        padding: "25px",
                                        padding: "25px",
                                        marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                        manualRefresh();
                                    }}
                                >
                                    Refresh
                                </button>
                            </>
                        ) : (
                            <button
                                style={{
                                    border: "1px solid black",
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "2rem",
                                    backgroundColor: "green",
                                    borderRadius: "10px",
                                    padding: "25px",
                                    padding: "25px",
                                    marginLeft: "5px",
                                }}
                                onClick={() => {
                                    devUpdate("reset");
                                }}
                            >
                                Reset
                            </button>
                        )}
                    </div>
                    <hr style={{ color: "black" }} />
                </div>
            ) : (
                <></>
            )}
            <div id="faceReq_modal">
                {/* <CancelPresentationIcon
          className="icon"
          sx={{ color: "#000" }}
          fontSize="large"
          onClick={() => closeFaceReq()}
        /> */}
                <h1>Face Recognition System</h1>
                <div className="row">
                    <div className="image">
                        {!faceReqData ? (
                            <h4>Photo here</h4>
                        ) : (
                            <img
                                src={faceReqData.originalImage}
                                alt="original image"
                                width="100%"
                            />
                        )}
                    </div>
                    <div className="image">
                        {!faceReqData ? (
                            <h4>Photo here</h4>
                        ) : (
                            <img
                                src={faceReqData.spottedImage}
                                alt="Spotted Image"
                                width="100%"
                            />
                        )}
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Reason</th>
                            <th>Name</th>
                            <th>Site Banned</th>
                            <th>Site Spotted At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!faceReqData ? (
                            <tr>
                                <th>---</th>
                                <th>---</th>
                                <th>---</th>
                                <th>---</th>
                            </tr>
                        ) : (
                            <tr>
                                <th>{faceReqData.reason}</th>
                                <th>{faceReqData.name}</th>
                                <th>{faceReqData.bannedSite}</th>
                                <th>{faceReqData.spottedSite}</th>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div style={{ margin: "0 auto", width: "25%" }}>
                    <select
                        style={{ padding: "10px", textAlign: "center" }}
                        onChange={(e) => setActionTaken(e.target.value)}
                    >
                        <option>---- ACTION TAKEN ----</option>
                        {actionList.map(function (d, i) {
                            return <option key={i}>{d.actionTaken}</option>;
                        })}
                    </select>
                    <button
                        style={{
                            marginLeft: "5px",
                            padding: "10px",
                            borderRadius: "5px",
                        }}
                        onClick={() => updateAction()}
                    >
                        Submit
                    </button>
                </div>

                <button
                    id="facereq"
                    style={{ display: "none" }}
                    onClick={() => playSound()}
                >
                    play
                </button>
            </div>

            <Modal open={openHistory} onClose={() => setOpenHistory(false)}>
                <div className="history">
                    <h2>Last 10 cars in the forecourt</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Reg Number</th>
                                <th>Time</th>
                                <th>Pump Number</th>
                                <th>Driven Off?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lastCars ? (
                                lastCars.map(function (d, i) {
                                    return (
                                        <IndividualHistory d={d} site={site} />
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                </div>
            </Modal>
            <Modal open={openProblemReport}>
                <div className="problemModal">
                    <h2>
                        <u>Report A Problem</u>
                    </h2>
                    <label>Problem: </label>
                    <input
                        type="text"
                        placeholder="Enter Problem Here..."
                        onChange={(e) => {
                            setProblem(e.target.value);
                        }}
                        autoFocus
                    />
                    <br />
                    <hr />
                    <img src={problemImg} width="600px" />
                    <br />
                    <button
                        onClick={() => {
                            setShowProblemBox(false);
                            setOpenProblemReport(false);
                        }}
                        style={{ backgroundColor: "red" }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => {
                            submitProblem();
                        }}
                        style={{ backgroundColor: "green" }}
                    >
                        Submit
                    </button>
                </div>
            </Modal>
            <Footer />
        </section>
    );
}

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import AddPerson from './components/AddPerson';
import FaceReq from './components/FaceReq';
import FaceReqTest from './components/FaceReqTest';
import Home from './components/Home';
import Autoport from './components/tabs/Autoport';
import Bearsden from './components/tabs/Bearsden';
import Braeside from './components/tabs/Braeside';
import Bridgeton from './components/tabs/Bridgeton';
import Rutherglen from './components/tabs/Rutherglen';
import { sentryInit } from './sentry';

function App() {
  sentryInit();
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/autoport" element={<Autoport />} />
          <Route path="/bearsden" element ={<Bearsden />} />
          <Route path="/breaside" element ={<Braeside />} />
          <Route path="/bridgeton" element ={<Bridgeton />} />
          <Route path="/rutherglen" element ={<Rutherglen />} />
          <Route path="/face-recognition" element={<FaceReq />} />
          <Route path="/add-person" element={<AddPerson />} />

        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;

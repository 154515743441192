import axios from "axios";
import { useState, useEffect } from "react";
import "./styles/faceReq.css";
import { Alert } from "@mui/material";

export default function AddPerson() {
    const url = "https://nitrous.rt-itservices.co.uk";

    const [site, setSite] = useState("");
    const [image, setImage] = useState("");
    const [reason, setReason] = useState("");
    const [sites, setSites] = useState([]);
    const [registration, setRegistration] = useState("");
    const [driveoffId, setDriveoffId] = useState("");

    //Alerts
    const [alert1, setAlert1] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        axios
            .get(`https://nitrous.rt-itservices.co.uk/sites/data`)
            .then((res) => {
                setSites(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const showAlert = (message, severity, duration = 3000) => {
        setMessage(message);
        setSeverity(severity);
        setAlert1(true);
        setTimeout(() => {
            setAlert1(false);
            setMessage("");
            setSeverity("");
        }, duration);
    };

    function handleSend() {
        const requiredFields = [site, image, reason];
        const requiredDriveOffFields = [
            site,
            image,
            reason,
            registration,
            driveoffId,
        ];

        if (requiredFields.some((a) => a === "")) {
            showAlert("Please complete all required fields", "warning");
        } else if (
            reason === "Drive off" &&
            requiredDriveOffFields.some((a) => a === "")
        ) {
            showAlert(
                "Please complete all required fields including registraion",
                "warning"
            );
        } else {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(image);
            fileReader.onload = () => {
                var data = {
                    site: site,
                    date: new Date(),
                    image: fileReader.result,
                    reason: reason,
                    registration: registration,
                    driveoffId: driveoffId,
                };

                axios
                    .post(`https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/add`, data)
                    .then((res) => {
                        alert(`Request added with name: ${res.data.name}`);
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                        showAlert("Error - Please try again");
                    });
            };
        }
    }

    return (
        <main className="addPerson">
            <h1>Add Person to Face Recognition System</h1>
            <div>
                <label>Site:</label>

                <select onChange={(e) => setSite(e.target.value)}>
                    <option>--select a site--</option>
                    {sites.map(function (d, i) {
                        return <option key={i}>{d.name}</option>;
                    })}
                </select>
            </div>
            <div>
                <label>Reason:</label>
                <select onChange={(e) => setReason(e.target.value)}>
                    <option>--Please Select--</option>
                    <option>Attempted Theft</option>
                    <option>Theft</option>
                    <option>Vandalism</option>
                    <option>Staff Abuse</option>
                    <option>Assualt</option>
                    <option>Drive off</option>
                </select>
            </div>
            {reason === "Drive off" ? (
                <>
                    <div>
                        <label>Registration:</label>
                        <input
                            type="text"
                            onChange={(e) => setRegistration(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Drive off ID:</label>
                        <input
                            type="text"
                            onChange={(e) => setDriveoffId(e.target.value)}
                        />
                    </div>
                </>
            ) : null}
            <div>
                <label>Image:</label>
                <input
                    type="file"
                    accepts="image/*"
                    onChange={(e) => setImage(e.target.files[0])}
                    style={{ border: "1px solid #be1a1a" }}
                />
            </div>

            {!alert1 ? (
                <button onClick={handleSend}>Send</button>
            ) : (
                <Alert severity={severity}>{message}</Alert>
            )}
        </main>
    );
}

import "./styles/home.css";
import { useNavigate } from "react-router-dom";


export default function Home() {
  const navigate = useNavigate()

  return (
    <section className="home">
      <h1>Drive off prevention system</h1>
      <div className="sites">
        <span onClick={() => navigate("/autoport")}>Autoport</span>
        <span onClick={() => navigate("/bearsden")}>Bearsden</span>
        <span onClick={() => navigate("/braeside")}>Braeside</span>
        <span onClick={() => navigate("/bridgeton")}>Bridgeton</span>
        <span onClick={() => navigate("/rutherglen")}>Rutherglen</span>
      </div>
    </section>
  );
}

import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";
import Modal from "@mui/material/Modal";
import {useState} from 'react'
import IndModal from "../IndModal";
import IconButton from "@mui/material/IconButton";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  

export default function IndividualHistory(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      if (open === true){
        window.location.reload()
      }
    }

    

    function handleDriveOff(plate, pump, date) {
        var month = new Date(date).getMonth() + 1;
        var day = new Date(date).getDate();
        var year = new Date(date).getFullYear();
        var hour = new Date(date).getHours();
        var mins = new Date(date).getMinutes();
    
        if (day < 10) {
          var newDay = "0" + day;
        } else {
          var newDay = day;
        }
    
        if (month < 10) {
          var newMonth = "0" + month;
        } else {
          var newMonth = month;
        }
    
        if (hour < 10) {
          var newHour = "0" + hour;
        } else {
          var newHour = hour;
        }
        if (mins < 10) {
          var newMins = "0" + mins;
        } else {
          var newMins = mins;
        }
        var fullDate = year + "-" + newMonth + "-" + newDay;
        var time = newHour + ":" + newMins;
        var confirm = window.confirm("Please confirm this was a drive off");
        if (confirm === true) {
          window.open(
            `https://driveoff.racetrackpitstop.co.uk/driveoff?reg=${plate}&pump=${pump}&site=${props.site}&date=${fullDate}&time=${time}`,
            "_blank"
          );
        } else {
          window.location.reload();
        }
      }
    
      var time = new Date(props.d.date).toLocaleString();


    return (
        <tr>
        <td
          style={{
            backgroundColor: `${props.d.status}`,
            color: "#fff",
            fontWeight: "bolder",
          }}
          onClick={handleOpen}
        >
          {props.d.licensePlate}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <IndModal d={props.d} />
          </Modal>
        </td>
        <td>{time}</td>
        <td>{props.d.line}</td>
        <td>
          <IconButton
            onClick={() =>
              handleDriveOff(props.d.licensePlate, props.d.line, props.d.date)
            }
          >
            <TaxiAlertIcon color="warning" />
          </IconButton>
        </td>
      </tr>
    )
}
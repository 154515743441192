import "../styles/pumps.css";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useState, useEffect , useRef } from "react";
import Pump from "../Pump";
import Footer from "../Footer";
import Header from "../Header";
import IndividualHistory from "./IndividualHistory";
import html2canvas from "html2canvas";


export default function Braeside() {
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState([]);
  const [lastCars, setLastCars] = useState([]);
  const [showProblemBox, setShowProblemBox] = useState(false)
  const [problemImg, setProblemImg] = useState("")
  const [problem, setProblem] = useState("")
  const exportRef = useRef();
  var site = "Braeside";


  useEffect(() => {
    setInterval(() => {
      axios
        .get(`https://nitrocam-back.nitrosolutions.co.uk/car/site?site=${site}`)
        .then((res) => {
          var x = res.data.filter((d) => d.site === site);
          setData(res.data);
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].status === "red") {
              setAlert((oldArray) => [...oldArray, res.data[i]]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(`https://nitrocam-back.nitrosolutions.co.uk/car/history?site=${site}`)
        .then((res) => {
          var z = res.data.filter((d) => d.site === site);
          setLastCars(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);
  }, []);

  function Close() {
    setAlert([]);
  }

  function handleDriveOff(plate, pump, date) {
    var month = new Date(date).getMonth()+1
    var day = new Date(date).getDate()
    var year = new Date(date).getFullYear()
    var hour = new Date(date).getHours()
    var mins = new Date(date).getMinutes()

    if (day < 10){
      var newDay = "0"+day
    } else {
      var newDay = day
    }

    if (month < 10){
      var newMonth = "0"+month
    } else {
      var newMonth = month
    }

    if (hour < 10){
      var newHour = "0"+hour
    }else {
      var newHour = hour
    }
    if (mins < 10){
      var newMins = "0"+mins
    }else {
      var newMins = mins
    }
    var fullDate = year + "-" + newMonth + "-" + newDay
    var time = newHour + ":" + newMins
    var confirm = window.confirm ("Please confirm this was a drive off")
    if (confirm === true) {
      window.open(`https://driveoff.racetrackpitstop.co.uk?reg=${plate}&pump=${pump}&site=Braeside&date=${fullDate}&time=${time}`, "_blank")
    } else {
      window.location.reload()
    }
  }

  const exportAsImage = async (el) => {
    if (showProblemBox) {
      setShowProblemBox(false)
    } else {
      const canvas = await html2canvas(el);
      const image = canvas.toDataURL("image/png", 1.0);
      setProblemImg(image)
      setShowProblemBox(true)
    }
  };

  const submitProblem = async () => {
    await axios.post("https://nitrocam-back.nitrosolutions.co.uk/car/help", { source: problemImg, problem: problem }).then((res) => {
      alert("Problem Has Been Submitted!")
      setShowProblemBox(false)
    }).catch((err) => {
      alert("An error has ocurred. Please tray again later.")
    })
  }

  return (
    <section className="home" ref={exportRef}>
      <Header site="Braeside"/>
      <button style={{ position: "fixed", bottom: "10px", right: "5px", borderRadius: "50%", backgroundColor: "lightblue", border: "2px solid navy", width: "50px", height: "50px" }} onClick={() => exportAsImage(exportRef.current, "test")}>?</button>
      <div className="pumpRow">
          <Pump pump = "1" site="Braeside" />
            <div className="pump">
              <div className="pumpNumber">1</div>
              <Divider sx={{ color: "#000" }} orientation="vertical" flexItem />
              <div className="pumpNumber">2</div>
            </div>
            <Pump pump = "2" site="Braeside" />
            <Pump pump = "3" site="Braeside" />
            <div className="pump">
              <div className="pumpNumber">3</div>
              <Divider sx={{ color: "#000" }} orientation="vertical" flexItem />
              <div className="pumpNumber">4</div>
            </div>
            <Pump pump = "4" site="Braeside" />
          </div>
          <div className="pumpRow">
          <Pump pump = "5" site="Braeside" />
            <div className="pump">
              <div className="pumpNumber">5</div>
              <Divider sx={{ color: "#000" }} orientation="vertical" flexItem />
              <div className="pumpNumber">6</div>
            </div>
            <Pump pump = "6" site="Braeside" />
            <Pump pump = "7" site="Braeside" />
            <div className="pump">
              <div className="pumpNumber">7</div>
              <Divider sx={{ color: "#000" }} orientation="vertical" flexItem />
              <div className="pumpNumber">8</div>
            </div>
            <Pump pump = "8" site="Braeside" />
          </div>
          <div className="history">
        <h2>Last 10 cars in the forecourt</h2>
        <table>
          <thead>
            <tr>
              <th>Reg Number</th>
              <th>Time</th>
              <th>Pump Number</th>
              <th>Driven Off?</th>
            </tr>
          </thead>
          <tbody>
          {lastCars ? (
              lastCars.map(function (d, i) {
                return (
                  <IndividualHistory d={d} site={site}/>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
      <Footer/>
      {showProblemBox ? (
        <div className="problemModal">
          <h2>
            <u>Report A Problem</u>
          </h2>
          <label>Problem: </label>
          <input
            type="text"
            placeholder="Enter Problem Here..."
            onChange={(e) => {
              setProblem(e.target.value);
            }}
            autoFocus
          />
          <br />
          <hr />
          <img src={problemImg} width="600px" />
          <br />
          <button
            onClick={() => {
              setShowProblemBox(false);
            }}
            style={{ backgroundColor: "red" }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              submitProblem();
            }}
            style={{ backgroundColor: "green" }}
          >
            Submit
          </button>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
}

import {  useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

export function sentryInit() {


    Sentry.init({
        dsn: "https://2d3859e53082491b81f0c096c17161d8@sentry.rt-itservices.co.uk/35",
        integrations: [
            new Sentry.browserTracingIntegration(),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
              }),
        ],
        tracesSampleRate: 1.0,
    });

}

// export { Sentry }
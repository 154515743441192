import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./styles/pumps.css";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function PumpModal(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [carDetails, setCarDetails] = useState(props);

    console.log(carDetails);

    function handleDriveOff(plate, pump) {
        var month = new Date().getMonth() + 1;
        var day = new Date().getDate();
        var year = new Date().getFullYear();
        var hour = new Date().getHours();
        var mins = new Date().getMinutes();

        if (day < 10) {
            var newDay = "0" + day;
        } else {
            var newDay = day;
        }

        if (month < 10) {
            var newMonth = "0" + month;
        } else {
            var newMonth = month;
        }

        if (hour < 10) {
            var newHour = "0" + hour;
        } else {
            var newHour = hour;
        }
        if (mins < 10) {
            var newMins = "0" + mins;
        } else {
            var newMins = mins;
        }
        var fullDate = year + "-" + newMonth + "-" + newDay;
        var time = newHour + ":" + newMins;
        var confirm = window.confirm("Please confirm this was a drive off");
        if (confirm === true) {
            window.open(
                `https://driveoff.racetrackpitstop.co.uk?reg=${plate}&pump=${pump}&site=${props.site}&date=${fullDate}&time=${time}`,
                "_blank"
            );
        } else {
            window.location.reload();
        }
    }

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Car Details on pump: {carDetails.d.line}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <ul className="modal_list">
                    <li>
                        Registration:{" "}
                        <span className="dvlaDetails">
                            {carDetails.d.licensePlate}
                        </span>
                    </li>
                    <li>
                        Confidence level:
                        <span className="dvlaDetails">
                            {carDetails.d.confidenceLevel}
                        </span>
                    </li>
                    <li>
                        DVLA Make:
                        <span className="dvlaDetails">
                            {carDetails.d.dvlaMake}
                        </span>
                    </li>
                    <li>
                        DVLA Color:
                        <span className="dvlaDetails">
                            {carDetails.d.dvlaColour}
                        </span>
                    </li>
                    <li>
                        Detected Colour:
                        <span className="dvlaDetails">
                            {carDetails.d.color.toUpperCase()}
                        </span>
                    </li>
                    <li>
                        DVLA MOT Status:
                        <span className="dvlaDetails">
                            {carDetails.d.dvlamot}
                        </span>
                    </li>
                    <li>
                        DVLA Fuel:
                        <span className="dvlaDetails">
                            {carDetails.d.dvlaFuel}
                        </span>
                    </li>
                    <li>
                        DVLA Registration Year:
                        <span className="dvlaDetails">
                            {carDetails.d.dvlaYear}
                        </span>
                    </li>
                    <li>
                        DVLA Tax Status:
                        <span className="dvlaDetails">
                            {carDetails.d.dvlatax}
                        </span>
                    </li>
                </ul>
                <IconButton sx={{ marginLeft: "42%" }}>
                    <TaxiAlertIcon
                        onClick={() =>
                            handleDriveOff(
                                carDetails.d.licensePlate,
                                carDetails.d.line
                            )
                        }
                        color="warning"
                        sx={{ fontSize: 50 }}
                    />
                </IconButton>
            </Typography>
        </Box>
    );
}

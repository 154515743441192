import { useState, useEffect } from "react";
import { io } from "socket.io-client";
import axios from "axios";
import "./styles/faceReq.css";
import useSound from "use-sound";
import facereq from "../images/facereq.mp3";
import MassiveLogo from "../images/noEntry.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { AlertTitle, Stack, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxHeight: "90vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "hidden",
};

const detailsStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "90vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
};

const tableContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    minWidth: "auto",
    margin: "0 auto",
};

const tableCellStyle = {
    textAlign: "center",
    border: "2px solid #d0d0e1",
    bgcolor: "white",
};
const tableCellStyleHead = {
    textAlign: "center",
    border: "2px solid #000",
    bgcolor: "#d0d0e1",
};

export default function FaceReq() {
    const [data, setData] = useState();
    const [color, setColor] = useState("");
    const [playbackRate, setPlaybackRate] = useState(0.75);
    const [actionTaken, setActionTaken] = useState("");
    const [actionList, setActionList] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [offline, setOffline] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState(null);
    const [zoomedImage, setZoomedImage] = useState("");

    const [play, { stop }] = useSound(facereq, {
        playbackRate,
        volume: 0.8,
    });

    useEffect(async () => {

        const queryParams = new URLSearchParams(window.location.search);
        var site = "";
        var orientation = "";
        site = queryParams.get("site");
        orientation = queryParams.get("orientation");

        if (orientation === "vertical") {
            document.getElementById(
                "row"
            ).style.cssText = `margin-left: -15%; max-width: 90vw; overflow-x: hidden;`;
            document.getElementsByTagName("h1").style.cssText =
                "margin-left: -15%;";
            document.getElementById("image").style.cssText =
                "overflow: hidden; width: 35vw; height: 30vh;";
            document.getElementsByTagName("table").style.cssText =
                "margin-left: -15%; width: 70vw;";
            document.getElementsByTagName("button").style.cssText =
                "margin-left: -15%;";
        }
        if (window.innerWidth < window.innerHeight) {
            document.getElementById(
                "row"
            ).style.cssText = `margin-left: -15%; max-width: 90vw; overflow-x: hidden;`;
            document.getElementsByTagName("h1").style.cssText =
                "margin-left: -15%;";
            document.getElementById("image").style.cssText =
                "overflow: hidden; width: 35vw; height: 30vh;";
            document.getElementsByTagName("table").style.cssText =
                "margin-left: -15%; width: 70vw;";
            document.getElementsByTagName("button").style.cssText =
                "margin-left: -15%;";
        }

        await axios
            .get(
                "https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/actions"
            )
            .then((resp) => {
                setActionList(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });

        const socket = io("https://nitrocam-back.nitrosolutions.co.uk", { query: `site=${site}` });
        // const socket = io("http://10.0.0.34:4024");
        socket.connect();

        socket.on(`${site}_face_data`, async (d) => {
            if (!offline) {
                await axios
                    .post(
                        "https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/received",
                        { name: d.name }
                    )
                    .then((resp) => {
                        console.log("response sent");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            console.log(d);
            setData(d);
            document.getElementById("facereq").click();
        });

        socket.on("disconnect", (d) => {
            console.log("DISCONNECTED :", d);
            setOffline(true);
        });

        setInterval(async () => {
            if (!offline) {
                await axios.post("https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/heartbeat", { site: site }).then((resp) => {
                    console.log(`Heartbeat Submitted - ${resp.data}`)
                }).catch((err) => {
                    console.log(err)
                })
            }
        }, 150000)

        return () => {
            socket.disconnect();
        };
    }, []);

    function playSound() {
        setPlaybackRate(playbackRate + 0.1);
        play();
    }

    function handleDealt() {
        window.location.reload();
    }

    const updateAction = async () => {
        if (actionTaken == "" || actionTaken == "---- ACTION TAKEN ----") {
            alert("ACTION MUST BE SELECTED");
        } else {
            await axios
                .post(
                    "https://nitrocam-back.nitrosolutions.co.uk/faceRecognition/update",
                    { name: data.name, note: actionTaken }
                )
                .then((resp) => {
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const handleImageClick = (imageSrc) => {
        setZoomedImage(imageSrc);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setZoomedImage("");
    };

    return (
        <main style={{ backgroundColor: color }}>
            <h1 style={{ fontSize: "1.1rem" }}>Face Recognition System</h1>
            {offline ?
                <Stack sx={{ width: "100%", marginBottom: "10px" }} spacing={5}>
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Warning</AlertTitle>
                        Internet Connectivity Has Been Lost
                    </Alert>
                </Stack>
                : null
            }
            <div className="row" id="row">
                <div className="photo">
                    <div
                        className="image"
                        id="image"
                        onClick={() => {
                            handleImageClick(data.originalImage)
                            if (!data) {
                                setSelectedImage(data.originalImage);
                                setOpen(true);
                            }
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {!data ? (
                            <img src={MassiveLogo} alt="no entry image" />
                        ) : (
                            <img
                                src={data.originalImage}
                                alt="original image"
                            />
                        )}
                    </div>
                    <strong>Stored Photo</strong>
                </div>
                <div className="photo">
                    <div
                        className="image"
                        id="image"
                        onClick={() => {
                            handleImageClick(data.spottedImage)
                            if (!data) {
                                setSelectedImage(data.spottedImage);
                                setOpen(true);
                            }
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {!data ? (
                            <img src={MassiveLogo} alt="no entry image" />
                        ) : (
                            <img src={data.spottedImage} alt="Spotted Image" />
                        )}
                    </div>
                    <strong>Live Photo</strong>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Reason</th>
                        <th>Name</th>
                        <th>Site Banned</th>
                        <th>Site Spotted At</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {!data ? (
                        <tr>
                            <th>---</th>
                            <th>---</th>
                            <th>---</th>
                            <th>---</th>
                        </tr>
                    ) : (
                        <tr>
                            <th>{data.reason}</th>
                            <th>{data.name}</th>
                            <th>{data.bannedSite}</th>
                            <th>{data.spottedSite}</th>
                            <th>
                                {data.cctvRequest && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setSelectedDetails(data.cctvRequest);
                                            setDetailsOpen(true);
                                        }}
                                        disabled={data.cctvRequest == null}
                                    >
                                        Show Details
                                    </Button>
                                )}
                            </th>
                        </tr>
                    )}
                </tbody>
            </table>
            <select
                style={{
                    width: "30%",
                    marginTop: "30px",
                    padding: "10px",
                    textAlign: "center",
                }}
                onChange={(e) => setActionTaken(e.target.value)}
            >
                <option>---- ACTION TAKEN ----</option>
                {actionList.map(function (d, i) {
                    return <option key={i}>{d.actionTaken}</option>;
                })}
            </select>
            <button onClick={() => updateAction()}>Submit</button>

            <button
                id="facereq"
                style={{ display: "none" }}
                onClick={() => playSound()}
            >
                play
            </button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        style={{
                            width: "90vh",
                            height: "90vh",
                        }}
                    >
                        <img
                            src={zoomedImage}
                            alt="enlarged-image"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                        />
                        <Button onClick={handleCloseModal} style={{ position: 'absolute', top: '0%', right: '0%', zIndex: '1' }}>
                            <CancelIcon />
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={detailsOpen}
                onClose={() => setDetailsOpen(false)}
                aria-labelledby="details-modal-title"
                aria-describedby="details-modal-description"
            >
                <Box sx={detailsStyle}>
                    <Typography id="details-modal-title" variant="h5" component="h2">
                        CCTV Request Details
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <div id="details-modal-description">
                        {selectedDetails ? (
                            <div>
                                <TableContainer component={Paper} sx={tableContainerStyle}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={tableCellStyleHead}><strong>Site</strong></TableCell>
                                                <TableCell sx={tableCellStyleHead}><strong>Reason</strong></TableCell>
                                                <TableCell sx={tableCellStyleHead}><strong>Status Name</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={tableCellStyle}>{selectedDetails.site}</TableCell>
                                                <TableCell sx={tableCellStyle}>{selectedDetails.reason}</TableCell>
                                                <TableCell sx={tableCellStyle}>{selectedDetails.statusName}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br />
                                <Typography variant="body1"><strong>Items:</strong> </Typography>
                                <div>{selectedDetails.items}</div>
                                <br />
                                <Typography variant="body1"><strong>Description:</strong> </Typography>
                                <div>{selectedDetails.description}</div>
                                <br />
                                <Typography variant="body1"><strong>Person:</strong></Typography>
                                <div>{selectedDetails.person}</div>
                                <br />
                                <Typography variant="body1"><strong>Notes:</strong></Typography>
                                <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Note</TableCell>
                                                <TableCell>Note Date</TableCell>
                                                <TableCell>User</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedDetails.notes.map((note, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{note.note}</TableCell>
                                                    <TableCell>{note.noteDate}</TableCell>
                                                    <TableCell>{note.user}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        ) : (
                            "No details available."
                        )}
                    </div>
                    <br />
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => setDetailsOpen(false)}
                        sx={{ mt: 2 }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </main>
    );
}
